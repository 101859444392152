exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-de-faq-tsx": () => import("./../../../src/pages/de/faq.tsx" /* webpackChunkName: "component---src-pages-de-faq-tsx" */),
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-de-package-transport-tsx": () => import("./../../../src/pages/de/package-transport.tsx" /* webpackChunkName: "component---src-pages-de-package-transport-tsx" */),
  "component---src-pages-de-people-transport-tsx": () => import("./../../../src/pages/de/people-transport.tsx" /* webpackChunkName: "component---src-pages-de-people-transport-tsx" */),
  "component---src-pages-de-terms-and-conditions-tsx": () => import("./../../../src/pages/de/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-de-terms-and-conditions-tsx" */),
  "component---src-pages-en-faq-tsx": () => import("./../../../src/pages/en/faq.tsx" /* webpackChunkName: "component---src-pages-en-faq-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-package-transport-tsx": () => import("./../../../src/pages/en/package-transport.tsx" /* webpackChunkName: "component---src-pages-en-package-transport-tsx" */),
  "component---src-pages-en-people-transport-tsx": () => import("./../../../src/pages/en/people-transport.tsx" /* webpackChunkName: "component---src-pages-en-people-transport-tsx" */),
  "component---src-pages-en-terms-and-conditions-tsx": () => import("./../../../src/pages/en/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nl-faq-tsx": () => import("./../../../src/pages/nl/faq.tsx" /* webpackChunkName: "component---src-pages-nl-faq-tsx" */),
  "component---src-pages-nl-index-tsx": () => import("./../../../src/pages/nl/index.tsx" /* webpackChunkName: "component---src-pages-nl-index-tsx" */),
  "component---src-pages-nl-package-transport-tsx": () => import("./../../../src/pages/nl/package-transport.tsx" /* webpackChunkName: "component---src-pages-nl-package-transport-tsx" */),
  "component---src-pages-nl-people-transport-tsx": () => import("./../../../src/pages/nl/people-transport.tsx" /* webpackChunkName: "component---src-pages-nl-people-transport-tsx" */),
  "component---src-pages-nl-terms-and-conditions-tsx": () => import("./../../../src/pages/nl/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-nl-terms-and-conditions-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-package-transport-tsx": () => import("./../../../src/pages/package-transport.tsx" /* webpackChunkName: "component---src-pages-package-transport-tsx" */),
  "component---src-pages-people-transport-tsx": () => import("./../../../src/pages/people-transport.tsx" /* webpackChunkName: "component---src-pages-people-transport-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-uk-faq-tsx": () => import("./../../../src/pages/uk/faq.tsx" /* webpackChunkName: "component---src-pages-uk-faq-tsx" */),
  "component---src-pages-uk-index-tsx": () => import("./../../../src/pages/uk/index.tsx" /* webpackChunkName: "component---src-pages-uk-index-tsx" */),
  "component---src-pages-uk-package-transport-tsx": () => import("./../../../src/pages/uk/package-transport.tsx" /* webpackChunkName: "component---src-pages-uk-package-transport-tsx" */),
  "component---src-pages-uk-people-transport-tsx": () => import("./../../../src/pages/uk/people-transport.tsx" /* webpackChunkName: "component---src-pages-uk-people-transport-tsx" */),
  "component---src-pages-uk-terms-and-conditions-tsx": () => import("./../../../src/pages/uk/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-uk-terms-and-conditions-tsx" */)
}

