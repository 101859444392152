export const onClientEntry = () => {
    if (document.readyState !== 'loading') {
        const myScript = document.createElement('script');
        myScript.src = 'https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js';
        myScript.onload = function() {
            window.cookieconsent.run({
                "notice_banner_type": "simple",
                "consent_type": "express",
                "palette": "light",
                "language": "pl",
                "page_load_consent_levels": ["strictly-necessary"],
                "notice_banner_reject_button_hide": true,
                "preferences_center_close_button_hide": true,
                "page_refresh_confirmation_buttons": false
            });
        };
        document.body.appendChild(myScript);
    }
    document.addEventListener('DOMContentLoaded', () => {
        const myScript = document.createElement('script');
        myScript.src = 'https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js';
        myScript.onload = function() {
            window.cookieconsent.run({
                "notice_banner_type": "simple",
                "consent_type": "express",
                "palette": "light",
                "language": "pl",
                "page_load_consent_levels": ["strictly-necessary"],
                "notice_banner_reject_button_hide": true,
                "preferences_center_close_button_hide": true,
                "page_refresh_confirmation_buttons": false
            });
        };
        document.body.appendChild(myScript);
    });
}